<template>
  <div id="elasticSearchApp">
    <ReactiveBase
        app="product"
        url="https://oms-ipgbook-rnssefv-arc.searchbase.io"
        credentials="b4930e275f5d:c73be2af-e280-491a-9006-9f3aa04da1be"
    >
      <div id="filterTemplate">


        <div class="filterTemplate_right">
          <div class="searchDirSearchFilterHolder">
            <div id="searchDirFilterColumn">

              <SingleList
                  componentId="InitialSearch"
                  dataField="filecode.keyword"
                  title="Default Filter"
                  sortBy="asc"
                  selectAllLabel=""
                  class="defaultFilterGroup"
                  :showSearch="false"
                  :customQuery="customQuery"
                  :react="setReactProp('default_react')"
              />

              <SingleList
                  componentId="Store"
                  dataField="filecode.keyword"
                  title="Store"
                  sortBy="asc"
                  selectAllLabel="All"
                  class="storeFilterGroup"
                  size="1999"
                  :showSearch="false"
                  :react="{ and: ['InitialSearch','Store','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] }"
              />

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Subject"
                    dataField="subjects.keyword"
                    title="by Subjects"
                    sortBy="asc"
                    selectAllLabel="All Subjects"
                    class="filterGroupInner"
                    :showSearch="false"
                    :react="setReactProp('subject_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Subjects</a>
              </div>

              <div class="filterGroup js-filterHolder" v-if="filecode!=''">
                <SingleList
                    componentId="Author"
                    dataField="authors.keyword"
                    class="filterGroupInner"
                    title="by Author"
                    sortBy="asc"
                    selectAllLabel="All Authors"
                    :showSearch="false"
                    :react="{ and: ['InitialSearch','Store','Subject','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] }"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Authors</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Status"
                    dataField="status.keyword"
                    class="filterGroupInner"
                    title="by Status"
                    sortBy="asc"
                    selectAllLabel="All Statuses"
                    :showSearch="false"
                    :react="setReactProp('status_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Statuses</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Format"
                    dataField="additional_format.keyword"
                    class="filterGroupInner"
                    title="by Format"
                    sortBy="asc"
                    selectAllLabel="All Formats"
                    :showSearch="false"
                    :react="setReactProp('format_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Formats</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Publisher"
                    dataField="publisher.keyword"
                    class="filterGroupInner"
                    title="by Publisher"
                    sortBy="asc"
                    selectAllLabel="All Publishers"
                    size="1999"
                    :showSearch="false"
                    :react="setReactProp('publisher_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Publishers</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Catalog"
                    dataField="catalog.keyword"
                    class="filterGroupInner"
                    title="by Catalog"
                    sortBy="asc"
                    selectAllLabel="All Catalogs"
                    :showSearch="false"
                    :react="setReactProp('catalog_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Catalogs</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="PublicationDate"
                    dataField="date_published_year.keyword"
                    class="filterGroupInner"
                    title="by Publication Date"
                    sortBy="desc"
                    selectAllLabel="All Publication Dates"
                    :showSearch="false"
                    :react="setReactProp('publication_date_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Publication Dates</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Pages"
                    dataField="number_of_pages_range.keyword"
                    title="by Number of Pages"
                    sortBy="asc"
                    class="filterGroupInner"
                    :showSearch="false"
                    :react="setReactProp('number_of_pages_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Pages</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Series"
                    dataField="seriesname.keyword"
                    title="by Series"
                    sortBy="count"
                    selectAllLabel="All Series"
                    class="filterGroupInner"
                    size="4999"
                    :showSearch=false
                    :react="setReactProp('series_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Series</a>
              </div>

              <div class="filterGroup js-filterHolder">
                <SingleList
                    componentId="Imprint"
                    dataField="imprint.keyword"
                    title="by Imprint"
                    sortBy="count"
                    selectAllLabel="All Imprint"
                    class="filterGroupInner"
                    size="1999"
                    :showSearch=false
                    :react="setReactProp('imprint_react')"
                />
                <a href="#" class="filterHolder-toggle js-filterHolder-toggle">+ View All Imprint</a>
              </div>

            </div>
          </div>

        </div>
        <div class="filterTemplate_left">
          <div class="filterTemplate_list">
            <ReactiveList
                componentId="SearchResult"
                dataField="title.keyword"
                sortBy="asc"
                :pagination="true"
                loader=""
                size="300"
                :showResultStats="false"
                :sortOptions="[
                      { label: 'sort by title', dataField: 'title.keyword', sortBy: 'asc' },
                      { label: 'sort by price', dataField: 'price.keyword', sortBy: 'asc' },
                      { label: 'sort by publication date', dataField: 'date_published_year.keyword', sortBy: 'asc' },
                      { label: 'sort by format', dataField: 'format.keyword', sortBy: 'asc' },
                      { label: 'sort by sales rank - 60', dataField: 'rank60.keyword', sortBy: 'asc' },
                      { label: 'sort by sales rank - 120', dataField: 'rank120.keyword', sortBy: 'asc' },
                      { label: 'sort by sales rank - 365', dataField: 'rank365.keyword', sortBy: 'asc' },

                    ]"
                className="result-list-container"
                :react="setReactProp('search_result_react')"
                :innerClass="{ list: 'elastic-books-container', poweredBy: '' }"
            >

              <div slot="onData" class="elastic-book-content" slot-scope="{ item }">
                <div class="product" :data-loaded="item.test">
                  <div class="mediumImgHolder">
                    <a :href="item.url">
                      <img :data-src="item.image.replace('images-bookstore-ipgbook.b-cdn.net','images.bookstore.ipgbook.com')" :title="item.title" :alt="item.title" width="128" height="150" border="0">
                    </a>
                  </div>
                  <div class="publisherTitle">
                    <a :href="item.url">{{ item.title }}&nbsp;&gt;</a>
                    <div class="authorStr" v-html="item.authors.join(', ')"></div>
                    <!--<p class="isbn_price">ISBN {{ item.isbn13 }}</p>
                    <p class="isbn_price">ISBN {{ item.additional_isbn13.join(', ') }}</p>-->
                    <!--<span class="hiddenForCogs">Price {{ item.price }}</span>
                    <span class="hiddenForCogs cogsCostPrice">Price {{ item.price }}</span>-->
                    <!-- <p class="isbn_price">{{ item.format }}</p>
                    <p class="isbn_price">{{ item.additional_format.join(', ') }}</p> -->
                  </div>
                </div>

              </div>
            </ReactiveList>
          </div>

          <div id="extra_search_button">
            <a href="#" class="searchDirViewMore">
              <img src="filebin/images/get_more_results.gif" alt="+ View More" title="+ View More" class="viewMoreBtn">
            </a>
          </div>

        </div>

        <div class="filterTemplate_loading"><div class="loading_text">Loading...</div></div>

      </div>
    </ReactiveBase>
  </div>
</template>

<script>
export default {
  name: "app",
  data () {
    return {
      filecode: this.$filecode,
      react_prop: {
        subject_react: {and: ['InitialSearch', 'Store', 'Status', 'Format', 'Publisher', 'Catalog', 'PublicationDate', 'Pages', 'Series', 'Imprint']},
        subject_react_publisher: {and: ['InitialSearch', 'Store', 'Author', 'Status', 'Format', 'Publisher', 'Catalog', 'PublicationDate', 'Pages', 'Series', 'Imprint']},
        status_react: { and: ['InitialSearch','Store','Subject','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        status_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        format_react: { and: ['InitialSearch','Store','Subject','Status','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        format_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        publisher_react: { and: ['InitialSearch','Store','Subject','Status','Format','Catalog','PublicationDate','Pages','Series','Imprint']  },
        publisher_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Catalog','PublicationDate','Pages','Series','Imprint']  },
        catalog_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','PublicationDate','Pages','Series','Imprint'] },
        catalog_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','PublicationDate','Pages','Series','Imprint'] },
        publication_date_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','Catalog','Pages','Series','Imprint'] },
        publication_date_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','Catalog','Pages','Series','Imprint'] },
        number_of_pages_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','Catalog','PublicationDate','Series','Imprint'] },
        number_of_pages_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','Catalog','PublicationDate','Series','Imprint'] },
        series_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','Catalog','PublicationDate','Pages','Imprint'] },
        series_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','Catalog','PublicationDate','Pages','Imprint'] },
        imprint_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series'] },
        imprint_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series'] },
        search_result_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        search_result_react_publisher: { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        default_react: { and: ['InitialSearch','Store','Subject','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
        default_react_publisher : { and: ['InitialSearch','Store','Subject','Author','Status','Format','Publisher','Catalog','PublicationDate','Pages','Series','Imprint'] },
      }
    }
  },
  methods: {
    setReactProp(name) {
      if (this.filecode !== '') {
        name += '_publisher';
      }
      return this.react_prop[name];
    },
    customQuery() {
      //console.log(oms_custom_elastic_filter_query);
      //console.log(oms_custom_elastic_search_query);
      return {
        "query": {
          "bool": {
            "should": oms_custom_elastic_search_query,
            "minimum_should_match" : 1
          }
        }
      }
    }
  }
}
</script>
