import Vue from "vue";
import App from "./App.vue";
import ReactiveSearch from "@appbaseio/reactivesearch-vue";
//import VueRs from "@appbaseio/reactivesearch-vue";

Vue.use(ReactiveSearch);
Vue.config.productionTip = true;
Vue.prototype.$filecode = '';

jQuery(document).ready(function(){

  // set file code aka publisher code from
  // data-term="filecode" data-value="ACN"
  const publisher_filecode = document.querySelector("[data-term='filecode']");

  if (publisher_filecode && publisher_filecode.hasAttribute('data-value')) {
    // set vue global var
    Vue.prototype.$filecode = publisher_filecode.getAttribute('data-value');
  }

  new Vue({
    render: h => h(App),
    data(){
      return {

      };
    },
    beforeMount:function(){

      // Construct Search Query
      // set in class.browse_titles_ext.php - outputElasticSearchApp()
      jQuery(".js-elastic-custom-search-query").each(function(){
        var query_part = {};
        query_part["match"] = {};

        query_part["match"][jQuery(this).attr("data-term")] = {
          "query": jQuery(this).attr("data-value"),
          "operator": "and"
        };

        oms_custom_elastic_search_query.push(query_part);

      });

    },
    mounted: function(){
      if( jQuery(".js-elastic-custom-search-query").length > 0 ) {
        jQuery(".js-elastic-custom-search-query").each(function () {
          var query_part = {};
          query_part["match"] = {};

          query_part["match"][jQuery(this).attr("data-term")] = {
            "query": jQuery(this).attr("data-value"),
            "operator": "and"
          };

          oms_custom_elastic_search_query.push(query_part);

        });
        oms_elastic_hide_carousels();
      }
      oms_elastic_watcher();
    },
    updated: function () {
    }
  }).$mount("#elasticSearchApp");
});


function oms_elastic_init(){
  // Format Header
  var header_html = jQuery(".js-elastic-custom-title").html();
  jQuery(".result-list-container div:first").addClass("result-list-container-header").prepend(header_html);

  // Pre-sort
  jQuery(".defaultFilterGroup input:first").trigger("click");


  // Show
  jQuery(".js-elastic-loading").hide();
  jQuery("#filterTemplate").show();

  // Button Trigger
  jQuery(".searchDirViewMore").click(function(e){
    e.preventDefault();
    oms_elastic_show_more($(".elastic-book-content:hidden"));
  });

  // Init Filter View All Buttons
  jQuery(".js-filterHolder-toggle").on("click",function(e){
    e.preventDefault();
    $(this).hide().parents(".js-filterHolder").addClass("open");
    oms_elastic_hide_carousels();
  });

}

function oms_elastic_watcher() {
  // Filter Watcher and Trigger
  var elasticSearchwWatcher = setInterval(function () {

    // Check if there was an Update
    var $test_content = $(".elastic-books-container .elastic-book-content:first .product");
    if ($test_content.attr("data-loaded") != "loaded") {

      // Initial One Time JS Styling
      if($(".result-list-container-header").length < 1) {
        oms_elastic_init();
      }

      // Initial One Time Publisher Store Select. Applied to SingleList componentId="Store" to filter by filecode
      // component class -> storeFilterGroup
      if ($(".js-elastic-custom-filter-query").length > 0) {
        if ($(".storeFilterGroup:visible").length > 0) {
          var storeClickTriggered = false;
          $(".storeFilterGroup input").each(function () {
            if ($(this).val() == $(".js-elastic-custom-filter-query").attr("data-value")) {
              $(this).trigger("click");
              storeClickTriggered = true;
              oms_elastic_show_more($(".elastic-book-content"));
            }
          });
          $(".storeFilterGroup").hide();
          if (!storeClickTriggered && document.querySelector("[data-term='filecode']")) {
            if (document.querySelector("[data-term='filecode']").getAttribute('data-value') !== '') {
              $(".filterTemplate_right").hide();
              $(".filterTemplate_left").html('No results found.');

            }
          }
        }
      } else {
        if ($(".storeFilterGroup:visible").length > 0) {
          $(".storeFilterGroup input:first").trigger("click");
          $(".storeFilterGroup").hide();
        }
      }

      // Initial One Time Search Term Set
      if ($(".defaultFilterGroup:visible").length > 0) {
        $(".defaultFilterGroup input:first").trigger("click");
        $(".defaultFilterGroup").hide();
      }


      // Re-init Filter View All Buttons
      jQuery(".js-filterHolder").each(function(){
        jQuery(this).removeClass("open");
        var item_count = jQuery(this).find("li").length;
        if(item_count == 0){
          jQuery(this).hide();
        }else if(item_count >= 5){
          jQuery(this).show();
          jQuery(this).find(".js-filterHolder-toggle").show();
        }else{
          jQuery(this).show();
          jQuery(this).find(".js-filterHolder-toggle").hide();
        }
      });

      // Show Initial 15
      oms_elastic_show_more($(".elastic-book-content"));

      // Loading Screen
      $(".filterTemplate_right label").on("click", function () {
        $(".filterTemplate_loading").show();
        $test_content.attr("data-loaded", "false");
        oms_elastic_hide_carousels();
      });

      $(".filterTemplate_loading").hide();

      // Set interval to not trigger
      $test_content.attr("data-loaded", "loaded");
    }

  }, 250);

  // Sorting Fix
  $("select[name=sort-options]").on("change", function () {
    oms_elastic_show_more($(".elastic-book-content"));
    oms_elastic_hide_carousels();
  });

}



function oms_elastic_show_more($target){
  $target.slice(0,15).each(function(){
    $(this).css("display","inline-block");
    $(this).find("img").attr("src",$(this).find("img").attr("data-src"));
  });

  if($(".elastic-book-content:hidden").length > 0){
    $(".searchDirViewMore").show();
  }else{
    $(".searchDirViewMore").hide();
  }
}

function oms_elastic_hide_carousels(){
  $(".filterTemplate_carousels").hide();
  $(".filterTemplate_left").show();
}